@import url("./responsive.css");

html,
body,
#root {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* COLORS */

  /* BACKGROUND */
  --primary-background-color: #f9f9f9;
  --secondary-background-color: #fff;

  --border: #e3e3e3;

  /* TEXT */
  --secondary-font-color: #3f3356;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.grid-template {
  display: grid;
  width: 100%;
  height: 100vh;
  grid-template:
    "header" 10vh
    "content"
    "footer" 5vh;

  text-align: center;
}

.header-grid {
  grid-area: header;
  text-align: right;
}

.content-grid {
  grid-area: content;
}

.footer-grid {
  grid-area: footer;
}

.form-control:disabled {
  opacity: 0.5 !important;
}
