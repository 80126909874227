.gallery .control-button {
    color: var(--slider-dots-background-color);
  }
  
  .gallery .control-button:hover {
    color: var(--slider-dots-background-color);
  }
  
  /*
      Theme controls how everything looks in Gallery CSS.
  */
  .gallery {
    position: relative;
  }
  .gallery .item {
    overflow: hidden;
    text-align: center;
  }
  .gallery .controls {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
  }
  .gallery .control-button {
    display: inline-block;
    margin: 0 10px;
    font-size: 5em;
    text-align: center;
    text-decoration: none;
    transition: color 0.1s;
    background-color: var(--slider-dots-background-color);
    border-radius: 100px;
  }
  
  .itemStartContent .circulo {
    display: flex;
    width: 350px;
    height: 350px;
    margin-bottom: 2rem;
    border-radius: 50%;
    background-color: var(--slider-dots-background-color);
    justify-content: center;
    align-items: center;
  }
  
  .circulo {
    background: var(--slider-dots-background-color);
    border-radius: 900px;
    margin: 20px 0;
    padding: 3em;
  }
  
  @keyframes controlAnimation-3 {
    0% {
      opacity: 0;
      width: 50px;
    }
  
    9.5%,
    33.3% {
      opacity: 1;
      width: 30px;
    }
  
    42.9%,
    100% {
      color: green;
    }
  }
  @keyframes galleryAnimation-3 {
    0% {
      opacity: 0;
    }
  
    9.5%,
    33.3% {
      opacity: 1;
    }
  
    42.9%,
    100% {
      opacity: 0;
    }
  }
  
  .gallery .item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.9s;
  }
  .gallery .control-operator {
    display: none;
  }
  .gallery .control-operator:target ~ .item {
    pointer-events: none;
    opacity: 0;
    animation: none;
  }
  .gallery .control-operator:target ~ .controls .control-button {
    animation: none;
  }
  
  .gallery .control-operator:nth-of-type(1):target ~ .item:nth-of-type(1) {
    pointer-events: auto;
    opacity: 1;
  }
  .gallery .control-operator:nth-of-type(2):target ~ .item:nth-of-type(2) {
    pointer-events: auto;
    opacity: 1;
  }
  
  .items-2.autoplay .control-button {
    animation: controlAnimation-3 1s infinite;
  }
  .items-2.autoplay .item {
    animation: galleryAnimation-3 2s infinite;
  }
  .items-2 .control-button:nth-of-type(1),
  .items-2 .item:nth-of-type(1) {
    animation-delay: -2s;
  }
  .items-2 .control-button:nth-of-type(2),
  .items-2 .item:nth-of-type(2) {
    animation-delay: 0.1s;
  }
  
  
  .gallery .control-operator:nth-of-type(1):target ~ .item:nth-of-type(1) {
    pointer-events: auto;
    opacity: 1;
  }
  .gallery .control-operator:nth-of-type(2):target ~ .item:nth-of-type(2) {
    pointer-events: auto;
    opacity: 1;
  }
  .gallery .control-operator:nth-of-type(3):target ~ .item:nth-of-type(3) {
    pointer-events: auto;
    opacity: 1;
  }
  
  .items-3.autoplay .control-button {
    animation: controlAnimation-3 21s infinite;
  }
  .items-3.autoplay .item {
    animation: galleryAnimation-3 21s infinite;
  }
  .items-3 .control-button:nth-of-type(1),
  .items-3 .item:nth-of-type(1) {
    animation-delay: -2s;
  }
  .items-3 .control-button:nth-of-type(2),
  .items-3 .item:nth-of-type(2) {
    animation-delay: 5s;
  }
  .items-3 .control-button:nth-of-type(3),
  .items-3 .item:nth-of-type(3) {
    animation-delay: 12s;
  }
  
  .gallery .control-operator:nth-of-type(1):target ~ .item:nth-of-type(1) {
    pointer-events: auto;
    opacity: 1;
  }
  .gallery .control-operator:nth-of-type(2):target ~ .item:nth-of-type(2) {
    pointer-events: auto;
    opacity: 1;
  }
  .gallery .control-operator:nth-of-type(3):target ~ .item:nth-of-type(3) {
    pointer-events: auto;
    opacity: 1;
  }
  .gallery .control-operator:nth-of-type(4):target ~ .item:nth-of-type(4) {
    pointer-events: auto;
    opacity: 1;
  }
  
  .items-4.autoplay .control-button {
    animation: controlAnimation-4 28s infinite;
  }
  .items-4.autoplay .item {
    animation: galleryAnimation-4 28s infinite;
  }
  .items-4 .control-button:nth-of-type(1),
  .items-4 .item:nth-of-type(1) {
    animation-delay: -2s;
  }
  .items-4 .control-button:nth-of-type(2),
  .items-4 .item:nth-of-type(2) {
    animation-delay: 5s;
  }
  .items-4 .control-button:nth-of-type(3),
  .items-4 .item:nth-of-type(3) {
    animation-delay: 12s;
  }
  .items-4 .control-button:nth-of-type(4),
  .items-4 .item:nth-of-type(4) {
    animation-delay: 19s;
  }
  
  .gallery .control-operator:nth-of-type(1):target ~ .item:nth-of-type(1) {
    pointer-events: auto;
    opacity: 1;
  }
  .gallery .control-operator:nth-of-type(2):target ~ .item:nth-of-type(2) {
    pointer-events: auto;
    opacity: 1;
  }
  .gallery .control-operator:nth-of-type(3):target ~ .item:nth-of-type(3) {
    pointer-events: auto;
    opacity: 1;
  }
  .gallery .control-operator:nth-of-type(4):target ~ .item:nth-of-type(4) {
    pointer-events: auto;
    opacity: 1;
  }
  .gallery .control-operator:nth-of-type(5):target ~ .item:nth-of-type(5) {
    pointer-events: auto;
    opacity: 1;
  }
  
  .items-5.autoplay .control-button {
    animation: controlAnimation-5 35s infinite;
  }
  .items-5.autoplay .item {
    animation: galleryAnimation-5 35s infinite;
  }
  .items-5 .control-button:nth-of-type(1),
  .items-5 .item:nth-of-type(1) {
    animation-delay: -2s;
  }
  .items-5 .control-button:nth-of-type(2),
  .items-5 .item:nth-of-type(2) {
    animation-delay: 5s;
  }
  .items-5 .control-button:nth-of-type(3),
  .items-5 .item:nth-of-type(3) {
    animation-delay: 12s;
  }
  .items-5 .control-button:nth-of-type(4),
  .items-5 .item:nth-of-type(4) {
    animation-delay: 19s;
  }
  .items-5 .control-button:nth-of-type(5),
  .items-5 .item:nth-of-type(5) {
    animation-delay: 26s;
  }
  
  
  .itemsStartChat {
    display: flex;
    flex-direction: column;
  
    justify-content: center;
    align-items: center;
  }
  
  .controls {
    display: none;
  }
  