/* === MEDIA QUERIES === */
/* Large Devices */
@media (min-width: 992px) {
  .checkout-main-content {
    width: 100%;
    margin: auto;
  }

  .card-owner-col {
    margin: auto;
  }
}

/* Medium Devices */
@media (max-width: 768px) {
}

/* Small Devices */
@media (max-width: 576px) {
  .grid-template {
    grid-template: "header" 5vh "content" "footer" 5vh !important;
  }

  .header-grid {
    text-align: center !important;
  }

  .header-grid img {
    width: 50%;
  }

  .content-grid .checkout-main-content-header {
    flex-direction: column;
    margin: 10px auto;
    height: auto;
  }
  .checkout-main-content-header-image img {
    height: 50px;
  }

  .header-polichat-polipay svg {
    width: 70px;
  }
  .header-polichat-polipay span {
    font-weight: bold;
  }

  .checkout-main-content-header-collapse {
    margin: 10px auto;
  }

  .checkout-main-content-body-image {
    display: none !important;
  }

  .checkout-main-content-body-form {
    border-right: none !important;
  }

  .body-header-container {
    flex-direction: row-reverse;
    align-items: center;
  }

  #locked-icon {
    margin-right: 10px;
  }

  .checkout-body-header-title {
    text-align: center !important;
    margin-top: 5px !important;
    margin-bottom: 10px !important;
  }
}

/* Extra Small Devices */
@media (max-width: 360px) {
  .checkout-form-col-buttons {
    flex-direction: column;
  }

  .checkout-form-col-buttons > * {
    margin: 5px;
  }
}
