/*
*  Checkout
*/
.checkout-container {
  background-color: var(--primary-background-color);
  padding: 3%;
  align-items: center;
  /* justify-content: center; */
}

/* imagem segurança */
.footer-content {
  align-self: baseline;
}

/* rodapé */
.footer-all {
  margin: 10px auto;
}

.footer-all span {
  color: var(--gray-75);
  font-size: 14px;
  margin-right: -10px;
  font-weight: 300;
}

.checkout-loading-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}

/* container principal */

.checkout-main-content {
  background: var(--secondary-background-color);
  border: 1px solid var(--border);
  box-sizing: border-box;
  border-radius: 5px;
}

.checkout-main-content-header {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  border-bottom: 1px solid var(--border);
  width: 95%;
  margin: 0 auto;
}

/* Logo e nome do customer */
.checkout-main-content-header-image {
  display: flex;
  flex: 1;
  align-items: center;
  text-align: center;
}

.checkout-main-content-header-image img {
}

.checkout-main-content-header-name {
  display: flex;
  align-items: center;
}

.header-customer-name {
  margin-left: 2px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: var(--black-dark-25);
  text-transform: uppercase;
}

/* img polypay quando não tem logo do customer */
.header-polichat-polipay {
  display: flex;
  align-items: center;
}

.header-polichat-polipay span {
  margin-left: 2px;
  color: var(--primary);
}

/* detalhes do pagamento e pedido */
.checkout-main-content-header-collapse {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.checkout-main-content-body {
  padding: 3%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.checkout-main-content-body-image {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 95%;
  padding-left: 25px;
  margin-left: 25px;
}

/* ===  Formulário === */
.checkout-main-content-body-form {
  display: flex;
  flex-direction: column;
  flex: 5;
  /* height: 100%; */
  border-right: 1px solid var(--border);
}

/* cabeçalho ~ mensagem de segurança  */
.checkout-body-header {
  display: flex;
  flex: 1;
  flex-direction: column;
  /* height: 100%; */
}

.body-header-container {
  display: flex;
}

.checkout-body-header-title {
  color: var(--secondary-font-color);
  font-family: Arial;
  font-size: 20px;
  text-align: initial;
  margin-top: 10px;
  margin-bottom: 30px;
}

.checkout-body-header-subtitle {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: initial;
}

/* inputs do formulário */
.checkout-body-content {
  /* height: 100%;
  display: flex;
  flex: 4; */
}

.checkout-form-col {
  margin-bottom: 10px;
  text-align: left;
}

.checkout-form-col-buttons {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  margin: auto;
}

.checkout-owner-label {
  margin-left: 20px;
}

/* === modal e tabela de detalhes === */
.checkout-details-table tr {
  text-align: center;
}

.checkout-details-table td {
  padding: 0.25rem !important;
  text-align: center;
}

.checkout-total-price {
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  color: #606161;
  float: right;
}

.billet-form-row {
  height: 500px;
}

.billet-form-col {
  height: 100%;
  width: 100%;
}

.billet-form {
  height: 100%;
}

.billet-form-iframe {
  height: 100%;
  width: 100%;
}

.checkout-order-processed {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/*

.checkout-safe-div {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 70px;
  position: fixed;
  top: 7%;
  
}

.checkout-safe-div-icon {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: var(--secondary);
}

.checkout-safe-text {
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 31px;
  line-height: 20px;
  margin-left: 10px;
  color: var(--secondary);
}

.loadingContainer {
  display: inline-block;
  vertical-align: inherit;
}


*/
